<template>
	<div class="page">
		<el-row class="tool-bar">
			<el-col :span="12">
				<search-tool v-model="search" @input="$utils('search', getPristupa)"/>
			</el-col>
		</el-row>
		<el-row>
			<!--Table-->
			<el-table v-if="pristup.length"
					:data="pristup"
					ref="table"
					:default-sort="sortBy"
					@sort-change="sort => $utils('changeSort', sort, getPristupa)"
					v-loading="gridLoad"
					border
					stripe>
				<el-table-column
						align="left"
						prop="naziv"
						:label="$t('pristup.naziv')"
						sortable="custom">
				</el-table-column>
				<el-table-column
						width="120">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top" v-if="$utils('roleUser', 'Pristupi', 'edit')">
							<el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/pristupi/' + scope.row.id + '')" size="small"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Pristupi', 'delete')">
							<el-button type="danger" icon="fas fa-trash" @click="$delete('pristupi', scope.row.id)" size="small"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
		</el-row>
		<br>
		<el-row>
			<pagination v-model="pagination" @input="getPristupa" ref="pagination"/>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'pristup-list',
	data() {
		return {
			pristup: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'naziv',
				order: 'ascending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('pristup.pristup')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getPristupa()]);
	},
	methods: {
		getPristupa() {
			this.gridLoad = true;
			return this.$get('pristupi', this.$utils('getParams')).then(data => {
				this.pristup = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		}
	}
};
</script>
